import axios from "axios";

// const baseURL = "http://localhost:3001";

// export const uploadUrl = "http://localhost:3001/uploads/";

const baseURL = "https://jhatkabyte.com/api/";

export const uploadUrl = "https://jhatkabyte.com/api/uploads/";

export const instance: any = axios.create({ baseURL });

export const axiosPrivate = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
