export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const GET_USER_INFO = "GET_USER_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const INVALID_USER = "INVALID_USER";
export const FORGOT_PASS_REQUEST = "FORGOT_PASS_REQUEST";
export const FORGOT_PASS_FAILURE = "FORGOT_PASS_FAILURE";
export const FORGOT_PASS_SUCCESS = "FORGOT_PASS_SUCCESS";
export const LOADER = "LOADER";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_ERROR";
export const RESET_STORE = "RESET_STORE";
export const RESET_PASS_REQUEST = "RESET_PASS_REQUEST";
export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const SET_CATEGORY_INFO = "SET_CATEGORY_INFO";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_ERROR = "CATEGORY_ERROR";
export const SET_PRODUCT_INFO = "SET_PRODUCT_INFO";
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const GET_PRODUCT_REVIEW = "GET_PRODUCT_REVIEW";
export const SET_PRODUCT_REVIEW = "SET_PRODUCT_REVIEW";
export const CART_ITEM = "CART_ITEM";
export const SET_CART_ERROR = "SET_CART_ERROR";
export const RESET_CART_ERROR = "RESET_CART_ERROR";
export const GET_CART_ITEM = "GET_CART_ITEM";
export const GET_CART_DETAILS = "GET_CART_DETAILS";
export const SET_CART_DETAILS = "SET_CART_DETAILS";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const SET_DISTRICT_ERROR = "SET_DISTRICT_ERROR";
export const SET_DISTRICT = "SET_DISTRICT";
export const PLACE_ORDER = "PLACE_ORDER";
export const SET_ORDER_ERROR = "SET_ORDER_ERROR";
export const SET_ORDER_DETAILS = "SET_ORDER_DETAILS";
export const REMOVE_EXISTING_CART = "REMOVE_EXISTING_CART";
export const UPDATE_PINCODE_ERROR = "UPDATE_PINCODE_ERROR";
export const UPDATE_PINCODE_SUCCESS = "UPDATE_PINCODE_SUCCESS";
export const RESET_PINCODES_STATUS = "RESET_PINCODES_STATUS";
export const RESET_CART_STATUS = "RESET_CART_STATUS";
export const CART_ITEM_SUCCESS = "CART_ITEM_SUCCESS";
export const SET_RESET_ORDER_STATUS = "SET_RESET_ORDER_STATUS";
export const RESET_ORDER_STATUS = "RESET_ORDER_STATUS";
export const USER_INFO = "USER_INFO";
export const ORDER_LIST = "ORDER_LIST";
export const SET_USER_ORDER_LIST = "SET_USER_ORDER_LIST";
export const EMPTY_CART = "EMPTY_CART";
export const SET_EMPTY_CART = "SET_EMPTY_CART";
export const ADD_REVIEW = "ADD_REVIEW";
export const SET_REVIEW = "SET_REVIEW";
export const SET_REVIEW_ERROR = "SET_REVIEW_ERROR";
export const GET_REVIEW = "GET_REVIEW";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const DISTRICT_LIST = "DISTRICT_LIST";
export const SET_DISTRICT_LIST = "SET_DISTRICT_LIST";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const SET_USER_ADDRESS = "SET_USER_ADDRESS";
export const ORDER_DATA = "ORDER_DATA";
export const SET_ORDER_DATA = "SET_ORDER_DATA";
export const RESET_USER_STATUS = "RESET_USER_STATUS";
export const SET_RESET_USER_STATUS = "SET_RESET_USER_STATUS";
export const SET_USER_ADDRESS_ERROR = "SET_USER_ADDRESS_ERROR";
export const SET_SHIPPING_COST = "SET_SHIPPING_COST";
export const GET_SHIPPING_COST = "GET_SHIPPING_COST";
export const CLEAR_STATUS_MESSAGE = "CLEAR_STATUS_MESSAGE";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT";
