import {
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  FORGOT_PASS_REQUEST,
  RESET_PASS_REQUEST,
  SOCIAL_LOGIN_REQUEST,
  FORGOT_PASSWORD_REQUEST,
} from "../actionTypes";

export const registerUserAction = (data: any) => {
  return { type: REGISTER_REQUEST, payload: data };
};
export const loginUsingSocialAccount = (token: string, loginType: number) => {
  return { type: SOCIAL_LOGIN_REQUEST, payload: { token, loginType } };
};
export const loginUserAction = (data: any) => {
  return { type: LOGIN_REQUEST, payload: data };
};
export const forgotPasswordAction = (email: string) => {
  return { type: FORGOT_PASSWORD_REQUEST, payload: email };
};
