import { instance } from "./axios";

export const request = (
  method: String,
  url: String,
  payload: any = null,
  headers: any = null
) => {
  console.log("method = ", method, "url = ", url, "payload = ", payload);
  const requestData: any = { method, url };
  const token = localStorage.getItem("token");
  if (payload) requestData.data = payload;
  requestData.headers = {
    Accept: "*/*",
  };
  if (headers) requestData.headers = { ...requestData.headers, ...headers };
  if (token)
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
  return instance(requestData);
};
